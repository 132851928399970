
/////////******************************************************************/
/**********************************Borders*****************************/
/////////******************************************************************/
//align-self-center


.BordeBlack{
  border: 2px solid black;
}

.BordeRed{
  border: 2px solid red;
}

.BordeBlue{
  border: 2px solid blue;
}

.BordeGreen{
  border: 2px solid green;
}


//font-family: MyriadPro-Regular;

/*******************************************************************************************/  
/*******************************************************************************************/ 
/*******************************************************************************************/ 

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) { 

}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {

}

/*******************************************************************************************/  
 /******************************************CONTAINER PAGE**********************************/
 /******************************************************************************************/  

.wrapper{
  padding-top: 75px;//58px;
}


/*******************************************************************************************/  
 /******************************************NAVBAR******************************************/
 /******************************************************************************************/  

.containerNavBar{
  -webkit-box-shadow: 0px 4px 8px -6px rgba(41,41,41, .5);
  -moz-box-shadow: 0px 4px 8px -6px rgba(41,41,41, .5);
  box-shadow: 0px 4px 8px -6px rgba(41,41,41, .5);
  //margin-bottom: 72px;//58px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
}

.navbar{
    background-color: white !important;
}

.nav-link{
    color: #0b005e !important;
    font-family: 'OpenSans';//MyriadPro-Regular;
    text-align: center;
    margin-left: 20px;
}

.nav-link.active{
  font-family: 'OpenSans-Bold';//MyriadPro-Regular;
  
}

.nav-link:hover{
    color: #685ad6 !important;
}

//Icon Search Bar

.icon-menu{
  cursor: pointer;
  padding: .5rem;
}


//Button register

.nav-link-register{
    background: #7c00ec;
    color: white !important;
    font-family: 'OpenSans';//MyriadPro-Regular;
    text-align: center;
    margin-left: 20px;
    border-radius: 20px;
    padding-left: 30px !important;
    padding-right: 30px !important;
}

.nav-link-register:hover{
    color: #d6d6d6 !important;
}

//private navbar


.op-navbar-settings img{
  content: url("../../assets/img/NavBar/BUT_settings_up.png");
}

.op-navbar-settings:active img{
  content: url("../../assets/img/NavBar/BUT_settings_press.png");
}


/*******************************************************************************************/  
 /******************************************SIDEBAR******************************************/
 /******************************************************************************************/  



.sidebar-wrapper{
  z-index: 10;
  position: fixed;/* Fixed Sidebar (stay in place on scroll) */
  width: $sidebar-size;
  height: 100%;
  overflow-y: hidden;
  background: #fff;//303336
  transition: all 0.3s ease;
  left:-$sidebar-size;
  padding-top: 60px;
  top: 0; /* Stay at the top */


  border-right:  1px solid #5f5f5f;

  /*-webkit-box-shadow: 6px 0px 14px -2px rgba(64,64,64,1);
-moz-box-shadow: 6px 0px 14px -2px rgba(64,64,64,1);
box-shadow: 6px 0px 14px -2px rgba(64,64,64,1);*/
}


.containerSideBar{
  height: 100%;
  padding-top: 50px;
}



//UL
.sidebar-nav, .sidebar-menu{
  padding: 0px;
  list-style: none;
}

.sidebar-nav li i{
  display: inline;
}

.sidebar-nav li a{
  display: block;
  text-decoration: none;
  color: white;
}
//END UL


.sidebar-nav-link{//active-sidebar-nav-link
  margin: 0px;
}

.sidebar-nav-link p{
  font-family: 'OpenSans-Bold';
  font-size: 15px;
}


//Icon
.imgSideBar{
  width: 65px;
  height: 65px;
}

.descrImgSideBar{
  margin-bottom: 0px;
  color: #0b005e;//white;
  font-size: 20px;
}

  //Revisar
  /*.opc-link{
    cursor: pointer;
    color: white;
    padding: 8px 40px;
    background: #3b3d40;
  }
  
  .opc-link.activeOpc{
    background: #505050;
  }*/
  //End REvisar


/*Change the width of the sidebar*/
//.menuDisplayed .sidebar-wrapper{ //wrapper.menuDisplayed
//   left: 0px;
  
//}


.op-sidebar-busqueda,
.op-sidebar-chat, 
.op-sidebar-filtro,
.op-sidebar-carpetas 
{
  margin: 0px;
}


.op-sidebar-chat, 
.op-sidebar-filtro,
.op-sidebar-carpetas {
  padding-left: 20px;
}


//Option Busqueda Change Image
.op-sidebar-busqueda img{
  content: url("../../assets/img/SideBar/MENU_busqueda_up.png");
}

.op-sidebar-busqueda:active img{
  content: url("../../assets/img/SideBar/MENU_busqueda_press.png");
}


//Option Filtro Change Image
.op-sidebar-filtro img{
  content: url("../../assets/img/SideBar/MENU_filtro_up.png");
}

.op-sidebar-filtro:active img{
  content: url("../../assets/img/SideBar/MENU_filtro_press.png");
}

//Option Carpetas Change Image
.op-sidebar-carpetas img{
  content: url("../../assets/img/SideBar/MENU_carpeta_up.png");
}

.op-sidebar-carpetas:active img{
  content: url("../../assets/img/SideBar/MENU_carpeta_press.png");
}

//Option Chat Change Image
.op-sidebar-chat img{
  content: url("../../assets/img/SideBar/MENU_chat_up.png");
}

.op-sidebar-chat:active img{
  content: url("../../assets/img/SideBar/MENU_chat_press.png");
}


@media (min-width: 1000px) { 
  /*Change the width of the sidebar*/
  .menuDisplayed .sidebar-wrapper{ //wrapper.menuDisplayed
      left: 0px;
  }
}


/********************************************************************************/
/**********************************Footer Navbar*********************************/
/********************************************************************************/

.fixed-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}


/*Change the width of the sidebar*/
.footer-wrapper{ //wrapper.menuDisplayed
  width: 100%;
  height: $footerbar-size;
  transition: all 0.3s ease;
  bottom:-$footerbar-size;
}

/*Change the width of the sidebar*/
//.menuDisplayed.footer-wrapper{ //wrapper.menuDisplayed
//  bottom: 0px;
//}


.container-footer-navbar{
  height: $footerbar-size;
}

.imageOptionFooter{
  width: 60px;
  height: 60px;
}


@media (max-width: 999px) { 
  .menuDisplayed.footer-wrapper{ //wrapper.menuDisplayed
      bottom: 0px;
  }
}





/***********************************************************************************/
/**********************************Page content*************************************/
/***********************************************************************************/

.page-content-wrapper{
  width: 100%;
  position: absolute;
  //padding: 0px 0px;//15px;
  //border: 2px solid green;
  //padding-left: 15px;
  transition: all 0.3s ease;
  word-wrap: break-word; 
}

@media (max-width: 575.98px) { 
  .page-content-wrapper{
    
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}


 // Small devices (landscape phones, less than 768px)
 @media (min-width: 1000px) {
  .menuDisplayed .page-content-wrapper{ //wrapper.menuDisplayed
      padding-left: $sidebar-size + 0px;//200+15
    }
}


// Small devices (landscape phones, less than 768px)
@media (max-width: 999px) {
  /*.menuDisplayed .page-content-wrapper{ //wrapper.menuDisplayed
    padding-left: 15px;
  }*/

  .page-content-wrapper{
    
      padding-bottom: $footerbar-size + 15px;

    }
}











/*****************************************************************************/
/**********************************ALL VIDEOS*********************************/
/*****************************************************************************/


video::-webkit-media-controls,
video::-moz-media-controls,
video::-webkit-media-controls-play-button, 
video::-webkit-media-controls-volume-slider,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-timeline, 
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-toggle-closed-captions-button,
audio::-webkit-media-controls-volume-slider
{
    display: none;
}


#controlBar .controlBar{
  display: none !important;
}

#controlBar{
  display: none !important;
}

.controlBar{
  display: none !important;
}


/*
video::-webkit-media-controls-play-button {}
video::-webkit-media-controls-volume-slider {}
video::-webkit-media-controls-mute-button {}
video::-webkit-media-controls-timeline {}
video::-webkit-media-controls-current-time-display {}
*/




/******************************************************************************/
/**********************************Carousel*********************************/
/**************************************************************************/

.carousel-control-next, .carousel-control-prev{
  top: 100px !important;
  bottom: 100px !important;
}


/******************************************************************************/
/**********************************Coming Soon*********************************/
/**************************************************************************/

.mainContainerComingSoon{
  margin-top: 60px;
}

.imgComingSoon{
  width: 450px;
  height: 450px;
}

.containerComingSoonTitle h1{
  padding-top: 40px;
  font-size: 55px;
  font-family: 'OpenSans-Bold';
  color: #0b005e;
}




@media (min-width: 1600px) {//992

    .containerComingSoonTitle{
      flex: 0 0 80%;
      max-width: 80%;
    }

    

}

@media  (min-width: 901px) and (max-width: 1599px){


    .imgComingSoon{
      width: 430px;
      height: 430px;
    }

    .containerComingSoonTitle{
      flex: 0 0 80%;
      max-width: 80%;
    }
    
  .containerComingSoonTitle h1{
    
    font-size: 50px;
  }
    
}

@media  (min-width: 601px) and (max-width: 900px){

  .imgComingSoon{
    width: 410px;
    height: 410px;
  }

  .containerComingSoonTitle{
    flex: 0 0 85%;
    max-width: 85%;
  }

  .containerComingSoonTitle h1{
    
    font-size: 45px;
  }
}
    
@media (max-width: 600px){

  .imgComingSoon{
    width: 390px;
    height: 390px;
  }

  .containerComingSoonTitle{
    flex: 0 0 85%;
    max-width: 85%;
  }

  .containerComingSoonTitle h1{
    
    font-size: 40px;
  }

}


/******************************************************************************/
/**********************************Pruebassssss*********************************/
/**************************************************************************/

.containerPruebaLoading{
  background: red;
}


.subContainerPruebaLoading{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
  opacity: .6;
}



.nav-item .classTestLogIn{
  cursor: pointer;
}

.classTestLogIn{
  padding: 8px;
  margin: 0;
  margin-left: 20px;
}