
  /***********************************************************************************/
  /**********************************Recientes****************************/
  /***********************************************************************************/
  
  .containerRecientes{
    padding-top: 30px;
    padding-bottom: 5px;
  }
  
  .containerRecientes h1{
    font-family: 'OpenSans-Bold';
  }
  
  /***********************************************************************************/
  /**********************************CandidatoRecienteInfo****************************/
  /***********************************************************************************/
  
  .mainContainerCandidatoReciente{
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }
  
  .CandidatoRecienteInfo{
      width: 200px;    
  }
  
  .contentCandidatoRecienteInfo{
      height: 100px;
  }
  
  //Imagen Profile
  .imgCandidatoRecienteInfo{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid #ebedfc;
  }
  
  
  .contentCandidatoRecienteInfoName{
    height: 40px;
    padding-left: 7px;
    padding-right: 7px;
  }
  
  .contentCandidatoRecienteInfoName p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'OpenSans-Bold';
    font-size: 18px;
  }
  
  .contentCandidatoRecienteInfoJob{
    height: 60px;
  }
  
  .contentCandidatoRecienteInfoJob p {
    width: 100%;
  }
  
   // Small devices (landscape phones, less than 768px)
   @media (max-width: 991.98px) {
       
      .CandidatoRecienteInfo{
          width: 150px;    
      }
  
      .contentCandidatoRecienteInfo{
          height: 90px;
      }
  }
  
  @media (max-width: 575.98px) { 
  
      .CandidatoRecienteInfo{
          width: 130px;    
      }
  
      .contentCandidatoRecienteInfo{
          height: 80px;
      }
  }
  
  
  
  
  
  @media (min-width: 1600px) {//992
  
    //Imagen Profile
    .imgCandidatoRecienteInfo{
      width: 150px;
      height: 150px;
    }
  }
  
  
  @media  (min-width: 1000px) and (max-width: 1599px){
  
     //Imagen Profile
     .imgCandidatoRecienteInfo{
      width: 140px;
      height: 140px;
    }
  
  }
  
  @media (min-width: 750px) and (max-width: 999px){
  
    //Imagen Profile
    .imgCandidatoRecienteInfo{
      width: 130px;
      height: 130px;
    }
  }
  
  
  @media (min-width: 650px) and (max-width: 749px){
  
    //Imagen Profile
    .imgCandidatoRecienteInfo{
      width: 120px;
      height: 120px;
    }
  }
  
  @media (max-width: 649px){
  
    //Imagen Profile
    .imgCandidatoRecienteInfo{
      width: 110px;
      height: 110px;
    }
  }
  
  
  
  
  /*******************************************************************************************/
  /**********************************CandidatoRecienteInfoSkeleton****************************/
  /*******************************************************************************************/
  
  .mainContainerCandidatoRecieteE{
    padding-top: 15px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .CandidatoRecienteInfoE{
    width: 200px;    
  }
  
  .contentCandidatoRecienteInfoE{
    height: 80px;
  }
  
  
  //Imagen Profile
  .imgCandidatoRecienteInfoE{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 3px solid #ebedfc;
  }
  
  
  .contentCandidatoRecienteInfoNameE{
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contentCandidatoRecienteInfoNameE p {
    width: 100%;
  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
    background: grey;
    border-radius: 50px;
    height: 22px;
  }
  
  
  .contentCandidatoRecienteInfoJobE{
    height: 60px;
  
    padding-left: 25px;
    padding-right: 25px;
  }
  
  .contentCandidatoRecienteInfoJobE p {
    width: 100%;
    background: grey;
    border-radius: 50px;
    height: 20px;
  }
  