
/*******************************************************************************/
/**********************************QuienesSomos*********************************/
/*******************************************************************************/

.mainContainerQuienesSomos{
    //padding-top: 55px;
    background: #0b005b;
    height: 100vh;
  }
  
  .containerImageQuienesSomos, .containerImageQuienesSomos2{
    width: 100%;
    height: 880px;
    position: relative;
  }
  
  .imgQuienesSomos{
    width: 100%;
    height: 100%;
  }
  
  
  .containerTxtQuienesSomos{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  
  
  .btnRegisterQuienesSomos{
  
    background: #7c00ec;
    color: white !important;
    font-family: MyriadPro-Regular;
    border-radius: 50px;
    font-size: 30px;
    padding-left: 30px !important;
    padding-right: 30px !important;
    margin-top: 30px;
  }
  
  .btnRegisterQuienesSomos:hover{
  
    color:  darken(white, 20%) !important;//white !important;
    //filter: brightness(85%);
  }
  
  .btnRegisterQuienesSomos:focus,
  .btnRegisterQuienesSomos:hover,
  .btnRegisterQuienesSomos:active{
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(255, 0, 0, 0.0);
  }
  
  
  //Img1
  
  .txtQuienesSomos{
    font-size: 50px;
    color: white; 
    text-align: center;
  }
  
  .descrTxtQuienesSomos{
    font-size: 30px;
    color: white; 
    text-align: center;
  }
  
  
  
  
  //Img2
  
  
  .titleQuienesSomosImg2{
    font-size: 50px;
    color: black; 
    text-align: center;
  }
  
  
  .descrTxtQuienesSomosImg2{
    font-size: 30px;
    color: black; 
    text-align: center;
  }
  
  
  //Img3
  
  .titleTxtQuienesSomosImg3{
    font-size: 50px;
    color: white; 
    text-align: center;
  }
  
  .descrTxtQuienesSomosImg3{
    font-size: 30px;
    color: white; 
    text-align: center;
  }
  
  
  //Img4
  
  .titleTxtQuienesSomosImg4{
    font-size: 50px;
    color: black;
    text-align: center;
  }
  
  .descrTxtQuienesSomosImg4{
    font-size: 30px;
    color: black; 
    text-align: center;
  }
  
  
  //Img5
  
  .titleTxtQuienesSomosImg5{
    font-size: 50px;
    color: white; 
    text-align: center;
  }
  
  .descrTxtQuienesSomosImg5{
    font-size: 30px;
    color: white; 
    text-align: center;
  }
  
  
  
  
  @media (min-width: 1800px) {//992
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 900px;
    }
     
  }
  
  
  @media  (min-width: 1500px) and (max-width: 1799px){
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 800px;
    }
     
  }
  
  @media  (min-width: 1200px) and (max-width: 1499px){
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 700px;
    }
  
    
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 40px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 26px;
    }
  
  
  
     
  }
  
  
  @media  (min-width: 980px) and (max-width: 1199px){
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 600px;
    }
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 40px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 24px;
    }
  
    .btnRegisterQuienesSomos{
      font-size: 27px;
    }
    
  
  }
  
  
  @media  (min-width: 850px) and (max-width: 979px){
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 500px;
    }
  
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 33px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 20px;
    }
  
  
    
    .btnRegisterQuienesSomos{
      font-size: 24px;
    }
    
     
  }
  
  
  @media  (min-width: 750px) and (max-width: 849px){
  
    .containerImageQuienesSomos, 
    .containerImageQuienesSomos2{
      
      height: 400px;
    }
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 28px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 17px;
    }
  
  
    .btnRegisterQuienesSomos{
      font-size: 24px;
    }
    
  
  }
  
  
  
  @media  (min-width: 650px) and (max-width: 749px){
  
    .containerImageQuienesSomos{
      
      height: 350px;
    }
  
    .containerImageQuienesSomos2{
      display: none;
    }
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 26px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 15px;
    }
  
    .btnRegisterQuienesSomos{
      font-size: 20px;
    }
    
  
  }
  
  
  
  @media (max-width: 649px){
  
    .containerImageQuienesSomos{
      
      height: 350px;
    }
  
    .containerImageQuienesSomos2{
      display: none;
    }
  
    .txtQuienesSomos,
    .titleQuienesSomosImg2,
    .titleTxtQuienesSomosImg3, 
    .titleTxtQuienesSomosImg4, 
    .titleTxtQuienesSomosImg5{
      
      font-size: 24px;
    }
  
    .descrTxtQuienesSomos, 
    .descrTxtQuienesSomosImg2, 
    .descrTxtQuienesSomosImg3, 
    .descrTxtQuienesSomosImg4, 
    .descrTxtQuienesSomosImg5{
      font-size: 13px;
    }
  
    .btnRegisterQuienesSomos{
      font-size: 18px;
    }
    
     
  }
  