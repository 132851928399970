

/**************************************************************************/
/**********************************LogIn*********************************/
/**************************************************************************/

.mainContainerLogIn{

  background: $background;
  height: 100vh;
  //margin-top: 60px;
  //padding: 0px 30px;
  padding-top: 160px; 
}

.containerPassword{//.md-form, 
  position: relative;
}
  
.inputFieldLogIn{
    border-radius: 50px;
    padding: .375rem 1.2rem !important;
    background-color: #e8e8e8;
    font-family: 'OpenSans';//'MyriadPro-Regular';//
}

.inputFieldLogIn::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b6b6b6;
    opacity: 1; /* Firefox */
}
  
.inputFieldLogIn:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #b6b6b6;
}

.inputFieldLogIn::-ms-input-placeholder { /* Microsoft Edge */
  color: #b6b6b6;
}


//LogIn
.btnLogIn{
    border-radius: 50px;
    font-size: 20px;
    font-family: 'OpenSans-Bold';
    background: #2132f3;
    border:  1px solid #2132f3;
}

.btnLogIn:hover,
.btnLogIn:focus,
.btnLogIn:active{
  background: #2132f3 !important;
  border:  1px solid #2132f3 !important;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
  filter: brightness(140%) !important;
}


//Register
.btnRegisterLogIn{
    border-radius: 50px;
    font-size: 20px;
    font-family: 'OpenSans-Bold';
    background: #7c00ec;
    border:  1px solid #7c00ec;
}

.btnRegisterLogIn:hover, 
.btnRegisterLogIn:focus, 
.btnRegisterLogIn:active{
    background: #7c00ec !important;
    border:  1px solid #7c00ec !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(221, 135, 217, 0.8) !important;
    filter: brightness(115%) !important;
}

//Icon Show/Hide Password
span.field-icon {
    position: absolute;
    display: inline-block;
    cursor: pointer;
    right: 1rem;
    top: 0.7rem;
    color: black;
    z-index: 2;
}

//Social Media
.titleSocialMedia{
  color: white;
  padding-top: 10px;
  padding-bottom: 15px;
  font-family: 'OpenSans-Bold';
}

//Icon Social Media
.imgSocialMedia{
  width: 60px;
  height: 60px;
}


.errorSignUp {
  margin-top: 10px;
  padding-left: 10px;
}


.errorSignUp span{
  color: red;
  font-size: 18px;
  //font-family: 'OpenSans-Bold';
}


@media (min-width: 1600px) {//992

  .mainContainerLogInColumns{
    flex: 0 0 35%;
    max-width: 35%;
  }
}


@media  (min-width: 801px) and (max-width: 1599px){

  .mainContainerLogInColumns{
    flex: 0 0 45%;
    max-width: 45%;
  }
    
}

@media  (min-width: 601px) and (max-width: 800px){

  .mainContainerLogInColumns{
    flex: 0 0 80%;
    max-width: 80%;
  }

}

@media (max-width: 600px){

  .mainContainerLogInColumns{
    flex: 0 0 100%;
    max-width: 100%;
  }
  
}
