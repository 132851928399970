
/***************************************************************************************************************/
/********************************************************MENU***************************************************/
/***************************************************************************************************************/
.mainContainerMenu{
  background: $background;
}
  
.subContainerMainMenu{
  
  background: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.subContainerMainMenu h1{
  font-family: 'OpenSans-Bold';
} 

.searchNoInformation h4 {
  font-size: 20px;
}



@media (min-width: 1600px) {//992

  .searchNoInformation h4 {
    font-size: 28px;
  }
}

@media  (min-width: 1000px) and (max-width: 1599px){
  
  .searchNoInformation h4 {
    font-size: 22px;
  }
}

@media (max-width: 999px){

  .searchNoInformation h4 {
    font-size: 17px;
  }
}

  
/**************************************************************************************/
/**********************************Search Load More************************************/
/**************************************************************************************/


.menu-btnLoadMore{
  border-radius: 50px;
  font-size: 20px;
  padding-left: 55px;
  padding-right: 55px;
  background: none;
  color: #0b005e;
  
  //border-color: #0b005e;
  border: 2px solid #e4e6fa;
}

.menu-btnLoadMore:focus, 
.menu-btnLoadMore:active{
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(255, 0, 0, 0.0);
}

.menu-btnLoadMore:hover{
  color: #a29bd8;
  
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.3), 0 0 8px rgba(133, 126, 192, 0.3);
}


/**************************************************************************/
/**********************************Noticia*********************************/
/**************************************************************************/

.carousel-indicators li {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.imageNoticia{
  width: 100%;
  height: 400px;
}


/*
.carousel-item {

  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}*/


/*******************************************************************************************/
/**********************************Scroll Candidatos recientes*********************************/
/*******************************************************************************************/

.containerScroll{
    position: relative;
}

.scrolling-wrapper {
      display: flex;    
      overflow-x: auto;
      position: relative;
}

.scrolling-wrapper {
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar {
    display: none;
}

/* Only FireFox */
.scrolling-wrapper {
  //scrollbar-color: #0064a7 #8ea5b5;
  scrollbar-width: none;
}

/***********************************************************************************************/
/**********************************Controls Candidatos recientes********************************/
/***********************************************************************************************/

.inner-controls{
    height: 0px;
}

.carousel-control {
    position: absolute;
    top: 45%;
    z-index: 2;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.carousel-control.left {
    margin-left: 0px; 
    color: black;   
    //right: auto;
    left: 60px;
    display: none;
    opacity: .7;
    transition: all 0.3s ease;
}


.carousel-control.right {
    margin-right: 0px; 
    color: black;
    //left: auto;
    right: 60px;
    display: none;
    //filter: brightness(.55);
    opacity: .7;
    transition: all 0.3s ease;
}

.carousel-control.left:hover,
.carousel-control.right:hover{
  //background: lighten(gray, 20%);
  //filter: brightness(1);
  opacity: 1;
}

.carousel-control.left.active, .carousel-control.right.active{
    display: inline-block;
}


.carousel-control img{
  
}



/***********************************************************************************/
/********************************** Buscar Next ************************************/
/***********************************************************************************/

.containerSearchNext{
  padding: 30px 0px;
}

/**********************************************************************************/
/**********************************Search Filter*********************************/
/********************************************************************************/


.btnSearch{
  border-radius: 50px;
  font-size: 20px;
  width: 180px;
  margin-left: 25px;
  font-family: 'OpenSans-Bold';
  background: #2132f3;
  border:  1px solid #2132f3;
}

.btnSearch:hover, 
.btnSearch:focus, 
.btnSearch:active{
  background: #2132f3 !important;
  border:  1px solid #2132f3 !important;
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
  filter: brightness(140%) !important;
}



/////////////////////////////////////////////////////
/////////////////////Filter Type Search//////////////
/////////////////////////////////////////////////////


.containerSearch{
  padding-top: 25px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  background: white;
  margin-bottom: 20px;
}

.containerSearch .css-2b097c-container.search{
  width: 250px !important;
  margin-right: 25px;
}

.containerSearch .css-yk16xz-control, .css-1pahdxg-control{
  border-radius: 50px !important;
  border: 2px solid #ebedfc !important;

  
  -webkit-appearance: none !important;
  background: url("../../assets/img/ICO_slide_16.png") no-repeat  #fff !important;//background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  background-position:
  calc(100% - 15px) .7em,
  calc(100% - 20px) 1em,
  100% 0 !important;
  
}

.containerSearch .css-tlfecz-indicatorContainer, 
.containerSearch .css-1gtu0rj-indicatorContainer{
  display: none !important;
}

.containerSearch .css-1uccc91-singleValue{
  color: #0b005e !important;
}


.containerSearch .css-1okebmr-indicatorSeparator{
  width: 0 !important;
}

.containerSearch .css-1pahdxg-control option{
  font-size: 45px;
}



.inputFieldSearchFilter {
  border-radius: 50px;
  padding: .375rem 1.2rem !important;
  //background-color: #e8e8e8;
  width: 250px !important;
  border: 2px solid #ebedfc !important;
  color: #0b005e !important;
}

//Select

select{
  -webkit-appearance: none !important;
  background: url("../../assets/img/ICO_slide_16.png") no-repeat  #fff !important;//background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  background-position:
  calc(100% - 15px) .7em,
  calc(100% - 20px) 1em,
  100% 0 !important;
}


select option:checked,
select option:focus {
    /*box-shadow: 0 0 10px 100px #000 inset;
    font-size: 25px;*/
    background: #1765f4 !important;
    color: white;
}


select option:hover {
  box-shadow: 0 0 10px 100px red inset !important;
}

select option:checked:after {
  content: attr(title);
  background: #666;
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  border: none;
}











@media (min-width: 1600px) {//992

  .containerSearch .css-2b097c-container.search{
    width: 240px !important;
    margin-right: 25px;
  }
  

  .btnSearch{
    margin-left: 25px;
  }
  
}


@media  (min-width: 1000px) and (max-width: 1599px){

  .containerSearch .css-2b097c-container.search{
    width: 220px !important;
    margin-right: 20px;
  }

  .btnSearch{
    margin-left: 20px;
  }
}


@media  (min-width: 681px) and (max-width: 999px){

  .containerSearch .css-2b097c-container.search{
    width: 200px !important;
    margin-right: 20px;
  }

  .inputFieldSearchFilter{
    width: 220px !important;
  }

  .btnSearch{
    margin-left: 20px;
  }
}



@media (max-width: 680px){

  .containerSearch .css-2b097c-container.search{
    //width: 200px !important;
    //margin-right: 15px;
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 15px;
  }

  .inputFieldSearchFilter{
    width: 100% !important;
  }

  .btnSearch{
    //margin-left: 15px;
    width: 100%;
    margin-left: 0;
    margin-top: 40px !important;
  }

}


  /*
@media (max-width: 999px){

  .css-2b097c-container.search{
    width: 200px !important;
    margin-right: 15px;
  }


  .inputFieldSearchFilter{


  }


  

  .btnSearch{
    //margin-left: 15px;
    width: 100%;
  }


}*/

/*
.containerBrand{
  width: 40px;
}*/



/*
.containerBrand a{
  text-align: center;
}*/