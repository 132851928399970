/**************************************************************************/
/**********************************Register*********************************/
/**************************************************************************/

.titleRegister{
    color: white;
    font-family: 'OpenSans-Bold';
}

.mainContainerRegisterColumns{
    //margin-top: 60px;
    padding-top: 160px; 
    background: $background;
    height: 100vh;
}

.mainContainerRegisterColumns label, .mainContainerRegisterColumns p{
    color: white;
}


.containerRegisterColumns{
    padding-left: 20px;
    padding-right: 20px;
    background: $background;
}

.inputFieldRegisterCity{
    
    border-radius: 50px;
    padding: .375rem 1.2rem !important;
    //background-color: #dcdff9 !important;
    font-family: 'OpenSans-Bold';
    color: #0b005e;
}


.inputFieldRegister{
    
    border-radius: 50px;
    padding: .375rem 1.2rem !important;
    //background-color: #dcdff9 !important;
    font-family: 'OpenSans';
    //font-family: 'OpenSans-Bold';
}



.inputFieldRegister::placeholder
{ 
    color: #c0c0c0;
}

.inputFieldRegister:-ms-input-placeholder{
    color: #c0c0c0;
}

.inputFieldRegister::-ms-input-placeholder{
    color: #c0c0c0;
}


.inputFieldRegisterBorderBottom{
    
    border-radius: 0px;
    padding: .375rem .2rem !important;
    border: none;
    background: transparent;
    border-bottom: 4px solid #7c00ec;
    color: white;
    font-family: 'OpenSans';
    //color: #0b005e;
}

.inputFieldRegisterBorderBottom:hover, 
.inputFieldRegisterBorderBottom:focus, 
.inputFieldRegisterBorderBottom:active{
    background: transparent;
    border-bottom:  4px solid #9949e2;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(255, 0, 0, 0.0);
    color: white;
}

.inputFieldRegisterBorderBottom::placeholder
{ 
    color: #c0c0c0;
}

.inputFieldRegisterBorderBottom:-ms-input-placeholder{
    color: #c0c0c0;
}

.inputFieldRegisterBorderBottom::-ms-input-placeholder{
    color: #c0c0c0;
}

  

//Labels
.label-input{
    padding-top: 0;
    padding-bottom: 0;
    font-family: 'OpenSans-Bold';
}


.label-input-p{
    font-family: 'OpenSans-Bold';
}



//Privacy Policy

.containerPrivacyPolicy{
    cursor: pointer;
    padding: 10px 0px !important;
    display: flex;
}

.containerImgPrivacyPolicy{
    width: 60px;
    float: left;
    height: 100%;
}
		
.imgCheckPrivacyPolicy{
    width: 60px;
    height: 60px;
}
					
.containerTextPrivacyPolicy{
    padding-left: 20px;
    align-items: center;
}
				
.subContainerTextPrivacyPolicy{
    float: none;
    width: auto;
    overflow: hidden;
}
            
.subContainerTextPrivacyPolicy p{
    font-family: 'OpenSans';
}


.errorRegister {
    margin-top: 10px;
    padding-left: 0;//5px;
}
  
  
.errorRegister span{
    color: red;
    font-size: 18px;
}


.btnRegister{
    border-radius: 50px;
    font-size: 20px;
    margin-top: 20px;
    font-family: 'OpenSans-Bold';
    background: #2132f3;
    border:  1px solid #2132f3;
}

.btnRegister:hover, 
.btnRegister:focus, 
.btnRegister:active{
    background: #2132f3 !important;
    border:  1px solid #2132f3 !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
    filter: brightness(140%) !important;
}

.btnRegister:disabled{
    background: #2132f3 !important;
    border:  1px solid #2132f3 !important;
    //box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
    filter: brightness(140%) !important;
}


.containerRegisterLoading {
    position: absolute;
    top: 76px;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .3;
}


@media (min-width: 1600px) {//992

    .mainContainerRegisterColumns{
        padding-top: 140px; 
    }

    /*
    .containerRegisterColumns.mobile, 
    .containerRegisterColumns.tablet{
        display: none;
    }*/
}


@media  (min-width: 1000px) and (max-width: 1599px){

    .mainContainerRegisterColumns{
        padding-top: 130px; 
    }

    /*
    .containerRegisterColumns.desktop, 
    .containerRegisterColumns.mobile{
        display: none;
    }*/

    .containerImgPrivacyPolicy{
        width: 50px;
    }
            
    .imgCheckPrivacyPolicy{
        width: 50px;
        height: 50px;
    }
}

@media (max-width: 999px){

    .mainContainerRegisterColumns{
        padding-top: 120px; 
    }

    /*
    .containerRegisterColumns.desktop, 
    .containerRegisterColumns.tablet{
        display: none;
    }*/

    .containerImgPrivacyPolicy{
        width: 45px;
    }
            
    .imgCheckPrivacyPolicy{
        width: 45px;
        height: 45px;
    }
  
}