/**************************************************************************************/
/**********************************OnBoarding Register*********************************/
/**************************************************************************************/

/*
select{
  
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
  -webkit-appearance: none;
  background-position:
  calc(100% - 15px) .7em,
  calc(100% - 20px) 1em,
  100% 0;
}*/

.mainContainerOnBoardingColumns{
    margin-top: 100px;
    margin-bottom: 50px;
  }
  
  
  .imgOnBoarding{
      width: 340px;
      height: 340px;
  }
  
  
  //Input field
  .inputFieldOnBoardingRegister{
      border-radius: 50px;
      padding: .375rem 1.2rem !important;
      font-family: 'OpenSans-Bold';
      color: #0b005e;
      border:  2px solid  #dcdff9;
  }
  
  
  .inputFieldOnBoardingRegister:hover, 
  .inputFieldOnBoardingRegister:focus, 
  .inputFieldOnBoardingRegister:active
  {
      background: transparent;
      color: #0b005e;
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(255, 0, 0, 0.0);
      border:  2px solid  #dcdff9;
  }
  
  .containerOnBoardingTitle{
    padding-top: 30px;
  }
  
  .containerOnBoardingTitle h1{
    font-family: 'OpenSans-Bold';
    font-size: 70px;
    color: #0b005e;
  }
  
  
  .containerOnBoardingFilter{
    padding-top: 30px;
  }
  
  .containerOnBoardingFilter label{
    //font-size: 40px;
    font-family: 'OpenSans-Bold';
    color: #0b005e;
  }
  
  .mainContainerOnBoardingColumns{
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  
  .btnRegisterOnBoarding{
    border-radius: 50px;
    font-size: 20px;
    margin-top: 20px;
    font-family: 'OpenSans-Bold';
    background: #2132f3;
    border:  1px solid #2132f3;
  }
  
  .btnRegisterOnBoarding:hover, 
  .btnRegisterOnBoarding:focus, 
  .btnRegisterOnBoarding:active{
    background: #2132f3 !important;
    border:  1px solid #2132f3 !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
    filter: brightness(140%) !important;
  }
  
  .btnRegisterOnBoarding:disabled{
    background: #2132f3 !important;
    border:  1px solid #2132f3 !important;
    //box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
    filter: brightness(140%) !important;
  }
  
  
  @media (min-width: 1600px) {//992
  
    /*
      .mainContainerOnBoardingColumns{
          flex: 0 0 30%;
          max-width: 30%;
      }*/
  
  
      .containerOnBoardingTitle{
        flex: 0 0 70%;
        max-width: 70%;
      }
  
      .containerOnBoardingFilter{
        flex: 0 0 30%;
        max-width: 30%;
      }
  
  }
  
  @media  (min-width: 901px) and (max-width: 1599px){
  
     /* .mainContainerOnBoardingColumns{
          flex: 0 0 40%;
          max-width: 40%;
      }*/
  
      .containerOnBoardingTitle h1{
        font-size: 60px;
      }
      
  
      .containerOnBoardingTitle{
        flex: 0 0 60%;
        max-width: 60%;
      }
  
      .containerOnBoardingFilter{
        flex: 0 0 40%;
        max-width: 40%;
      }
     
  
      .imgOnBoarding{
        width: 320px;
        height: 320px;
    }
  }
  
  @media  (min-width: 601px) and (max-width: 900px){
  
      /*
      .mainContainerOnBoardingColumns{
          flex: 0 0 70%;
          max-width: 70%;
      }*/
      .containerOnBoardingTitle h1{
        font-size: 50px;
      }
      
  
      .containerOnBoardingTitle{
        flex: 0 0 80%;
        max-width: 80%;
      }
  
      .containerOnBoardingFilter{
        flex: 0 0 70%;
        max-width: 70%;
      }
  
      .imgOnBoarding{
        width: 310px;
        height: 310px;
    }
  }
      
  
  @media (max-width: 600px){
  
    /*
      .mainContainerOnBoardingColumns{
          flex: 0 0 100%;
          max-width: 100%;
      }*/
      .containerOnBoardingTitle h1{
        font-size: 45px;
      }
      
  
      .containerOnBoardingTitle{
        flex: 0 0 90%;
        max-width: 90%;
      }
  
      .containerOnBoardingFilter{
        flex: 0 0 90%;
        max-width: 90%;
      }
  
      
      .imgOnBoarding{
        width: 300px;
        height: 300px;
    }
  
  }
  
  
  
  