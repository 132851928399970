/****************************************************************************************/
/**********************************PROFILE DEL CANDIDATO*********************************/
/****************************************************************************************/

.mainContainerProfileCandidato{
    background: $background;
  }
  
  
  .subContainerProfileCandidato{
   
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  
  }
  
  
  .containerTypeVideoCandidato{
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  
  
  /*
  @media (min-width: 1600px) {//992
  
    .subContainerProfileCandidato_C1, .subContainerProfileCandidato_C2{
      flex: 0 0 50%;
      max-width: 50%;
    }
  
  }*/
  
  
  @media (min-width: 1000px){//(min-width: 1150px) and (max-width: 1599px){
  
    .subContainerProfileCandidato_C1, .subContainerProfileCandidato_C2{
       flex: 0 0 50%;
       max-width: 50%;
    }
  }
  
  @media (max-width: 999px){
  
    .subContainerProfileCandidato_C1, .subContainerProfileCandidato_C2{
       flex: 0 0 100%;
       max-width: 100%;
    }
  
  }
  
  
  
  
  ///////////////////////////////////////////////////////////////
  //////////////////HEADER PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  .containerHeaderProfileCandidato{
    padding: 40px 20px !important;
  }
  
  
  .mainContainerPictureProfileCandidato{
    height: 104px;//100%;
  }
  
  .containerImgProfileCandidato {
    width: 100px;
    float: left;
    display: list-item;
    cursor: pointer;
  }
  
  
  //Imagen de perfil
  .imgProfileCandidato{
    width: 100%;
    height: 100px;
    border-radius: 50%;
    border: 6px solid white;
  }
  
  
  
  .containerInfoProfileCandidato{
   padding-left: 10px;
   height: 100%;//100px;
   display: flex;
   align-items: flex-end;//center;
  }
  
  
  //Informacion Name and Job
  .subcontainerInfoProfileCandidato {
   float: none; 
   width: auto;
   overflow: hidden;   
  }
  
  .subcontainerInfoProfileCandidato h4{
    color: white; 
    font-family: 'OpenSans-Bold';
  }
  
  .subcontainerInfoProfileCandidato p{
   font-size: 16px;
   color: white;
  }
  
  
  .subcontainerInfoProfileCandidato .cardStar{
    margin-left: 2px;
     margin-right: 2px;
  }
  
  
  
  
  
  @media (min-width: 1500px) {//(min-width: 1150px) and (max-width: 1599px){
  
    .mainContainerPictureProfileCandidato {
      height: 120px;
    }
  
    .containerImgProfileCandidato {
      width: 120px;
    }
  
  
    //Imagen de perfil
    .imgProfileCandidato{
      height: 120px;
    }
  
  }
  
  
  
  @media (min-width: 1000px) and (max-width: 1499px){//(min-width: 1150px) and (max-width: 1599px){
  
    .mainContainerPictureProfileCandidato {
      height: 110px;
    }
  
    
    .containerImgProfileCandidato {
      width: 110px;
    }
  
  
    //Imagen de perfil
    .imgProfileCandidato{
      height: 110px;
    }
  
  
  }
  
  
  
  
  
  
  
  
  
  //////////////////////////////////////////////////////////////////
  //////////////////END HEADER PROFILE CANDIDATO////////////////////
  //////////////////////////////////////////////////////////////////
  
  
  
  
  //////////////////////////////////////////////////////////////
  /////////////////PREPARE PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  .containerPrepareProfileCandidato{
    padding: 15px 25px !important;
  }
  
  
  .mainContainerPrepareItemProfileCandidato{
    padding: 10px 0px !important;
    //margin: 10px 15px;
  }
  
  .containerImgPrepareProfileCandidato {
    width: 65px;
    float: left;
  
  }
  
  .imgPrepareCandidatoInfo{
    width: 100%;
    height: 65px;
  }
  
  
  .containerInfoPrepareProfileCandidato{
    padding-left: 20px;
    height: 100%;
    display: flex;
    align-items: flex-end;//center;
   }
   
   
   //Informacion Name and Job
   .subcontainerInfoPrepareProfileCandidato {
    float: none; 
    width: auto;
    overflow: hidden;   
   }

   .subcontainerInfoPrepareProfileCandidato h1{
    //font-family: 'OpenSans-Bold';
    font-size: 26px;
    color: #0b005e;
   }
  
   .subcontainerInfoPrepareProfileCandidato p{
     color: #7b7b7b;
   }


   .subcontainerInfoPrepareProfileCandidato .cardStar{
     margin-left: 2px;
     margin-right: 2px;
   }

  
   .subcontainerInfoPrepareProfileCandidato > * {//last-child
    margin-bottom: 0 !important;
  }
  
  
  
  
  
  @media (min-width: 1500px) {//(min-width: 1150px) and (max-width: 1599px){
  
    .containerImgPrepareProfileCandidato {
      width: 75px;
    }
  
    .imgPrepareCandidatoInfo{
      height: 75px;
    }
  
  }
  
  
  @media (min-width: 1000px) and (max-width: 1499px){//(min-width: 1150px) and (max-width: 1599px){
  
    .containerImgPrepareProfileCandidato {
      width: 70px;
    }
  
    .imgPrepareCandidatoInfo{
      height: 70px;
    }
  
  }
  
  
  
  //////////////////////////////////////////////////////////////
  /////////////////END PREPARE PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  
  //////////////////////////////////////////////////////////////
  /////////////////SOBRE MI PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  .containerSobreMiProfileCandidato{
    //border-bottom: 3px solid #ebedfc;
    padding: 0px 25px !important;
  }
  
  .containerSobreMiProfileCandidato h1{
    font-family: 'OpenSans-Bold';
    color: #0b005e;
    font-size: 25px;
  }
  
.containerSobreMiProfileCandidato p{
  color: #7b7b7b;
}

  //////////////////////////////////////////////////////////////
  /////////////////END SOBRE MI PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  
  //////////////////////////////////////////////////////////////
  /////////////////CONTACTO PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  .containerContactoProfileCandidato {
    padding: 15px 25px !important;
    //border-bottom: 3px solid #ebedfc;
  }

  .containerContactoProfileCandidato h2{
    font-family: 'OpenSans-Bold';
    color: #0b005e;
    font-size: 24px;
  }
  
.containerContactoProfileCandidato strong{
  font-family: 'OpenSans-Bold';
  font-size: 18px;
}

  //////////////////////////////////////////////////////////////
  /////////////////END CONTACTO PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  
  
  
  
  
  
  //////////////////////////////////////////////////////////////
  /////////////////CALIFICAR PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  .containerCalificarProfileCandidato {
    padding: 15px 25px !important;
    //border-bottom: 3px solid #ebedfc;
  }
  
  
  .containerCalificarDescrProfileCandidato {
    width: auto;
    float: left;
  }

  .containerCalificarDescrProfileCandidato h4{
    font-family: 'OpenSans-Bold';
    color: #2132f3;
    font-size: 22px;
  }
  
  //////////////////////////////////////////////////////////////
  /////////////////END CALIFICAR PROFILE CANDIDATO////////////////////
  ///////////////////////////////////////////////////////////////
  
  
  .borderPrepareProfileCandidato{
    border-bottom: 3px solid #ebedfc;
    margin: 0px 25px;
  }
  
  
  .imgVideoCandidatoProfile{
      width: 100px;
      height: 100px;
      margin: 0px 10px;
  }
  
  .imgAboutCandidatoProfile{
      width: 65px;
      height: 65px;
  }
  
  
  
  @media (min-width: 1500px) {//(min-width: 1150px) and (max-width: 1599px){
  
    .imgVideoCandidatoProfile{
      width: 120px;
      height: 120px;
    }
  
  }
  
  
  
  @media (min-width: 1000px) and (max-width: 1499px){//(min-width: 1150px) and (max-width: 1599px){
  
    .imgVideoCandidatoProfile{
      width: 110px;
      height: 110px;
      
    }
  
  }
  
  







  
/**************************************************************************************/
/**********************************VIDEO CANDIDATO PROFILE*****************************/
/**************************************************************************************/

.wrapperVideoProfile{
    width: 280px;//Video width
    height: 300px;//Video height
    border-top-left-radius: 12px;//border-radius: 16px; 
    border-top-right-radius: 12px;//border-radius: 16px; 
    overflow: hidden;
  }
  
  .wrapperVideoProfile video {
    object-fit: fill;
    width:0%;
    height:0%;
  }
  
  .wrapperVideoProfile video.enable{
    width:100%;
    height:100%;
  }
  
  
  .previewVideoProfile{
    object-fit: fill;
    width:100%;
    height:100%;
  }
  
  
  //Main controls
  .containerControlsVideoCandidatoProfile{
    width: 280px; //Controls Video width
    background: #0b005e;
    padding: 5px 5px;
    padding-right: 10px;
    border-bottom-left-radius: 12px;//border-radius: 16px; 
    border-bottom-right-radius: 12px;//border-radius: 16px; 
  }
  
  
  //Container left 
  .leftControlsVideoCandidatoProfile{
    flex: 0 0 20%;
    max-width: 20%;
    display: inline;
  }
  
  .btnPlaySlider{
    width: 40px;
    height: 40px;
  }
  
  //Container rigth
  .containerSliderVideoCandidatoProfile{
    flex: 0 0 80%;
    max-width: 80%;
    display: inline;
    position: relative;
  }
  
  
  /*
  .slider-container{
    position: relative;
  }*/
  
  .bar{
  position: absolute;
    z-index: 1;
    left: 0px;
    top: 10.5px;
    width: 100%;
    height: 8px;
    border-radius: 4px;
    background-color: white;
    overflow:hidden;
  }
  
  .fill{
    display:block;
    width: 0%;
    height: 100%;
    background-color: #2233f3;
  }
  
  
  .slider {
    position: relative;
    z-index: 2;
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 2px;
    outline: none;
    background-color: transparent !important;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 22.5px;
    height: 22.5px;
    background: #2233f3;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 0 rgba(98,0,238, .1);
    transition: .3s ease-in-out;
  }
  
  .slider::-moz-range-thumb {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    background: #2233f3;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    box-shadow: 0 0 0 0 rgba(34,51,243, .1);
    transition: 3s ease-in-out;
  }
  
  
  
  @media (min-width: 1500px) {//(min-width: 1150px) and (max-width: 1599px){
  
    .wrapperVideoProfile{
      width: 360px;//Video width 280
      height: 380px;//Video height 300
    }
  
      //Main controls
    .containerControlsVideoCandidatoProfile{
      width: 360px; //Controls Video width 280
    }
  
  }
  
  
  
  @media (min-width: 1000px) and (max-width: 1499px){//(min-width: 1150px) and (max-width: 1599px){
  
    .wrapperVideoProfile{
      width: 320px;//Video width 280
      height: 340px;//Video height 300
    }
  
      //Main controls
    .containerControlsVideoCandidatoProfile{
      width: 320px; //Controls Video width 280
    }
  
  }
  
  /*
  @media (max-width: 999px){
  
    .subContainerProfileCandidato_C1, .subContainerProfileCandidato_C2{
       flex: 0 0 100%;
       max-width: 100%;
    }
  
  }*/