
$primary: #ff6b39;
$background: #0b005e;//0b005e
$container: #f2f3f4;
$btn: #2ecc71;


$sidebar-size: 120px;//140
$footerbar-size: 90px;


$videoCandidatoProfile-size: 90px;

@font-face {
  font-family: 'MyriadPro-Regular';
  src: local('MyriadPro-Regular'), url(../../assets/font/MyriadPro-Regular.otf) format('otf');
}


@font-face {
  font-family: 'OpenSans';
  src: url(../../assets/font/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url(../../assets/font/OpenSans-Bold.ttf) format('truetype');
}
