@import './base/settings';
@import './components/variables';
//@import './base/base';

// Components
@import './components/terms';
@import './components/privacy';
@import './components/onboardingRegister';
@import './components/candidatoReciente';
@import './components/candidatoInfo';
@import './components/services';
@import './components/profileCandidato';
@import './components/menu';
@import './components/logIn';
@import './components/quienesSomos';
@import './components/settings';
@import './components/register';
@import './components/newstyles';
@import './components/changePassword';
@import './components/footer';

@import './components/all';
@import './components//pruebas';