
/**************************************************************************/
/**********************************Servicios*********************************/
/**************************************************************************/

.containerHeaderServicios{
  width: 100%;
  height: 880px;
  position: relative;
}
  
  .subContainerHeaderServiceColumns{
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
  
  
.subContainerHeaderServiceColumns h1{
  font-size: 55px;
  color: #0b005e;
  font-family: 'OpenSans-Bold';
}
  
  
.containerServicios{
  padding-top: 50px;
  padding-left: 40px;
  padding-right: 40px;
}

.mainContainerServicios{
  padding-top: 40px;
  padding-bottom: 40px;
}

.subContainerServicios{
  padding-left: 40px;
  padding-right: 40px;
}

.btnServicios{
  border-radius: 50px;
  font-size: 24px;
  font-family: 'OpenSans-Bold';
}

.btnServiciosComprar{
  border-radius: 50px;
  font-size: 24px;
  font-family: 'OpenSans-Bold';
}
  

  @media (min-width: 1800px) {//992
  
    .containerHeaderServicios{
      
      height: 850px;
    }
     
  }

  
@media  (min-width: 1500px) and (max-width: 1799px){

  
  .containerHeaderServicios{
    
    height: 800px;
  }

  .subContainerServicios{
    padding-left: 80px;
    padding-right: 80px;
  }
    

    
  .subContainerHeaderServiceColumns h1{
    font-size: 50px;
  }
}
  


@media  (min-width: 1200px) and (max-width: 1499px){

  
  .containerHeaderServicios{
    
    height: 700px;
  }

  .subContainerHeaderServiceColumns h1{
    font-size: 45px;
  }
    
}

  
  @media  (min-width: 980px) and (max-width: 1199px){
  
    .containerHeaderServicios{
      
      height: 600px;
    }

    .subContainerHeaderServiceColumns h1{
      font-size: 40px;
    }

  }
  
  
  @media  (min-width: 850px) and (max-width: 979px){

    .containerHeaderServicios{
      
      height: 500px;
    }
  
    .subContainerHeaderServiceColumns h1{
      font-size: 40px;
    }
     
  }
  
  
  @media  (min-width: 750px) and (max-width: 849px){
  
    
    .containerHeaderServicios{
      
      height: 400px;
    }

    .subContainerHeaderServiceColumns h1{
      font-size: 40px;
    }
  
  }
  
  
  
  @media  (min-width: 650px) and (max-width: 749px){
  
    .containerHeaderServicios{
      height: 350px;
    }

    .subContainerHeaderServiceColumns h1{
      font-size: 35px;
    }

  }
  
  
  
  @media (max-width: 649px){
  
    .containerHeaderServicios{
      
      height: 350px;
    }

  
    .subContainerHeaderServiceColumns h1{
      font-size: 35px;
    }  
     
  }
  
  
  
  
  ////////////////////////////////////////////////////
  ///////////////////////PLAN/////////////////////////
  ////////////////////////////////////////////////////


  .containerPlanButton, .containerButton {
    padding-top: 25px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
  
  .containerPlan{
    padding: 25px 20px;
  }
  
  .containerPlanDescr{
    padding-top: 15px;
    padding-bottom: 15px;
  
    border-top: 3px solid #ebedfc;
    border-bottom: 3px solid #ebedfc;
  }
  
  .containerPlanDetail{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  


  @media (min-width: 1600px) {//992
  
    .containerPlan{
       flex: 0 0 33.333333%;
       max-width: 33.333333%;
    }
  
    
    .containerButton{
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  
  
    .containerPlanButton {
      display: none;
    }
  
  }
  
  @media  (min-width: 1150px) and (max-width: 1599px){
  
    .containerPlan{
      flex: 0 0 33.333333%;
       max-width: 33.333333%;
    }
  
     .containerButton{
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    .containerPlanButton {
      display: none;
    }
  
  
  }
  
  @media (max-width: 1149px){
  
    .containerPlan, .containerPlanButton{
       flex: 0 0 100%;
       max-width: 100%;
    }
  
    .containerButton{
      display: none;
    }
  
  }
  