/**************************************************************************/
/**********************************Settings*********************************/
/**************************************************************************/


/////////////////////////////////////////////////////
//////////////////HEADER SETTINGS////////////////////
/////////////////////////////////////////////////////

.containerHeaderSettings{
    padding: 40px 20px !important;
}
  
.containerInfoSettings{
    padding-left: 10px;
    height: 100px;
    display: flex;
    align-items: center;
}
  
/////////////////////////////////////////////////////////
//////////////////END HEADER SETTINGS////////////////////
/////////////////////////////////////////////////////////

.mainContainerSettingsColumns{
    background: $background;
}

.containerSettingsColumns{
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px; 
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}


.inputFieldSettingsBorderBottom{
    
    border-radius: 0px;
    padding: .375rem .2rem !important;
    border: none;
    background: transparent;
    border-bottom: 4px solid #ebedfc;
}


.inputFieldSettingsBorderBottom:hover, 
.inputFieldSettingsBorderBottom:focus, 
.inputFieldSettingsBorderBottom:active,
.inputFieldSettingsBorderBottom:disabled{
    background: transparent;
    border-bottom:  4px solid #ebedfc;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(255, 0, 0, 0.0);
}

//Privacy
.containerPrivacyPolicy p{
    font-size: 20px;
}

.containerPrivacyPolicy span {
    font-size: 20px;
    text-decoration: underline;
    font-family: 'OpenSans-Bold';
}

//Social media
.settingsSocialMedia h1{
    font-size: 22px;
}


.errorSettings {
    margin-top: 10px;
    padding-left: 0;//5px;
}


.errorSettings span {
    color: red;
    font-size: 18px;
}


.btnSettings{
    border-radius: 50px;
    font-size: 20px;
    margin-top: 20px;
    font-family: 'OpenSans-Bold';
    background: #2132f3;
    border:  1px solid #2132f3;
}

.btnSettings:hover, 
.btnSettings:focus, 
.btnSettings:active{
    background: #2132f3 !important;
    border:  1px solid #2132f3 !important;
    box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.0), 0 0 8px rgba(90, 78, 247, 0.8) !important;
    filter: brightness(140%) !important;
}


.subcontainerImgProfileCandidato{
    width:inherit;
    height: inherit;
    position: absolute;
    display: inline;
}

.editImgProfileCandidato{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 0;
    right: 0;
}


@media (min-width: 1600px) {//992

    //Icon Social Media
    .imgSocialMediaSettings{
        width: 60px;
        height: 60px;
        margin: 0px 10px;
    }

}


@media  (min-width: 1000px) and (max-width: 1599px){
    
    //Icon Social Media
    .imgSocialMediaSettings{
        width: 50px;
        height: 50px;
        margin: 0px 8px;
    }
}

@media (max-width: 999px){

      //Icon Social Media
      .imgSocialMediaSettings{
        width: 40px;
        height: 40px;
        margin: 0px 6px;
    }
}

