


////////////////////////////////////////////////


/*
.leftContainerMain{
   height: 100%;
   display: flex;
   align-items: center;
}

*/

////////////////////////////////////////////////
////////////////////PRUEBAS/////////////////////
////////////////////////////////////////////////

.containerEjemplos{
   //height: auto;
   overflow: hidden;
}


.NuevaleftPrincipal {
   width: 180px;
   float: left;
   background: #aafed6;
}


.NuevarightPrincipal {
   float: none; 
   width: auto;
   overflow: hidden;   
}

.Nuevaleft {
   width: 180px;
   float: right;
   background: #aafed6;
}

.Nuevaright {
   float: none; 
   width: auto;
   overflow: hidden;   
}

.Nuevaclear {
   clear: both;
}



////////////////////////////////////////////////
////////////////////PRUEBAS/////////////////////
////////////////////////////////////////////////

.pruebaSizeDiv{
   width: 100px;
   height: 100px;



   /*white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;*/ // This is where the magic happens
}

.pruebaSizeDiv p {
   width: 100%;
   height: 100%;
   
   
   white-space: nowrap; //white-space: inherit;
   
   overflow: hidden;
   //text-overflow: ellipsis;
   border: 1px solid #000000;

   //overflow-wrap: break-word;
   //hyphens: auto;
   
 }


 

 .module {
   width: 250px;
   margin: 0 0 1em 0;
   overflow: hidden;
 }
 .module p {
   margin: 0;
 }
 

 .overflow {
   --max-lines: 3;
   position: relative;
   max-height: calc(var(--lh) * var(--max-lines));
   overflow: hidden;
   padding-right: 1rem; /* space for ellipsis */
 }
 .overflow::before {
   position: absolute;
   content: "...";
 /*   inset-block-end: 0;
   inset-inline-end: 0; */
   bottom: 0;
   right: 0;
 }
 .overflow::after {
   content: "";
   position: absolute;
 /*   inset-inline-end: 0; */
   right: 0;
   width: 1rem;
   height: 1rem;
   background: white;
 }
 
