/*********************************************************************************************/
  /**********************************CONTAINER ITEMS CANDIDATO INFO*****************************/
  /*********************************************************************************************/
  
  .containerItemsCandidatoInfoLoading{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
    opacity: .6;
  }
  
    
  /*****************************************************************************/
  /**********************************CANDIDATO INFO*****************************/
  /*****************************************************************************/
  
  .mainContainerCandidatoInfo{
    padding: 15px 5px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  
  //Contenedor del video lado izquierdo
  .leftMainContainerCandidatoInfo {
    width: 220px;
    float: left;
    height: 100%;
    margin-right: 10px;
  }
  
  
  .leftSubContainerCandidatoInfo{
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  ///Video
  
  .containerVideoCandidatoInfo{
    width: 100%;//450px;
    height: 250px;  //Tamano del video fig2 //100%;//270px;//450px;//100%;//
    border-radius: 16px; 
    overflow: hidden;
  }
  
  //Image No Video
  .imgVideoCandidatoInfo{
    object-fit: fill; 
    width:100%;
    height:100%;
  }
  
  .containerVideoCandidatoInfo video {
    object-fit: fill;
    width:0%;
    height:0%;
  }
  
  .containerVideoCandidatoInfo video.enable{
    width:100%;
    height:100%;
  }
  
  //FIN Video
  
  
  //
  .rightMainContainerCandidatoInfo{
    height: 100%;
    align-items: center;
  }
  
  
  
  //Contenedor de la informacion lado derecho
  .rightSubContainerCandidatoInfo {
  
    height: 100%;
    flex: 0 0 100%;
    max-width: 100%;
  
    display: flex;
    align-items: center;
  
    border-top: 3px solid #ebedfc;
    border-bottom: 3px solid #ebedfc;
  
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  .rightFillContainerCandidatoInfo{
    width: 100%;
  }
  
  
  //Contenedor imagen de perfil
  .leftContainerImageProfileCandidatoInfo {
     width: 70px;//Img Fig1
     float: left;
     height: 100%;
  }
  
  .leftSubContainerImageProfileCandidatoInfo{
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  
  //Imagen de perfil
  .imgProfileCandidatoInfo{
    width: 100%;//80px;//Img Fig1
    height: 70px;//Img Fig1
    border-radius: 50%;
  }
  
  
  
  
  
  .rightContainerUserInfoCandidatoInfo{
    padding-left: 10px;
    height: 100%;
  }
  
  
  .rightSubContainerUserInfoCandidatoInfo {
    //display: inline;
    width: 100%;
  }
  
  
  //Informacion Name and Job
  .leftContainerUserDetailCandidatoInfo {
    float: none; 
    width: auto;
    overflow: hidden;   
  }

  .leftContainerUserDetailCandidatoInfo h1{
    font-family: 'OpenSans-Bold';
    font-size: 18px;
  }
  

  .leftContainerUserDetailCandidatoInfo p{

    font-size: 15px;
    
  }
  
  
  //Stars
  .containerStarCandidatoInfo {
    
    width: 100px;
    float: right;
  }
  
.containerStarCandidatoInfo .cardStar{
  margin-right: 2px;
  margin-left: 2px;
}

  //IconStar
  .cardStar{
    height: 15px !important;
    width: 15px !important;
    transform: translateY(-10%);
  }
  
  
  
  //Sobre Mi
  .containerAboutMeCandidatoInfo{
    padding-top: 10px;
    width: 100%;
  }
  
  .containerAboutMeCandidatoInfo h1{
    font-size: 22px;
    font-family: 'OpenSans-Bold';
    color: #0b005e;
  }
  
  .containerAboutMeCandidatoInfo p{
    font-size: 14px;
    color: #7b7b7b;
  }
  
  
  @media (min-width: 1600px) {//992
  
    .mainContainerCandidatoInfo{
       flex: 0 0 33.333333%;
       max-width: 33.333333%;
    }
  
  }
  
  @media  (min-width: 1150px) and (max-width: 1599px){
  
    .mainContainerCandidatoInfo{
       flex: 0 0 50%;
       max-width: 50%;
    }
  
  }
  
  @media (max-width: 1149px){
  
    .mainContainerCandidatoInfo{
       flex: 0 0 100%;
       max-width: 100%;
    }
  
    .leftMainContainerCandidatoInfo{
       display: none;
    }
  
    .rightSubContainerCandidatoInfo {
       padding-left: 0px;
    }
  }
  
  
  
  
  
  /**************************************************************************************/
  /**********************************CANDIDATO INFO SKELETON*****************************/
  /**************************************************************************************/
  
  .mainContainerCandidatoInfoE{
    padding: 15px 5px;
    margin-bottom: 25px;
  }
  
  
  
  .candidatoInfoNameE{
    background: grey;
    border-radius: 50px;
    //background: grey;
    width: 60%;
    height: 20px;
  
    margin-bottom: 4px;
  }
  
  .candidatoInfoJobE{
    background: grey;
    border-radius: 50px;
    height: 20px;
  }
  
  
  
  .aboutMeTitleE{
    //font-size: 25px;
    background: grey;
    border-radius: 50px;
    height: 25px;
    width: 25%;
    margin-bottom: 15px;
  }
  
  
  .aboutMeDescrE{
    background: grey;
    border-radius: 50px;
    height: 20px;
    margin-bottom: 10px;
    //width: 20%;
  }
  
  @media (min-width: 1600px) {//992
  
    .mainContainerCandidatoInfoE{
       flex: 0 0 33.333333%;
       max-width: 33.333333%;
    }
  
  }
  
  
  @media  (min-width: 1150px) and (max-width: 1599px){
  
    .mainContainerCandidatoInfoE{
       flex: 0 0 50%;
       max-width: 50%;
    }
  
  }
  
  @media (max-width: 1149px){
  
    .mainContainerCandidatoInfoE{
       flex: 0 0 100%;
       max-width: 100%;
    }
  
  }
  